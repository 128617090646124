import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { useLocation } from "react-router-dom";
import { ROUTE } from "utils/constants";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const { pathname } = location;
  const onClickDrawerOpen = () => {
    setIsOpen(!isOpen);
  };

  const isExtendLayout =
    pathname !== ROUTE.SIGN_IN.URL &&
    pathname !== ROUTE.RESET_PASSWORD.URL &&
    pathname !== "/404";

  return (
    <>
      {isExtendLayout ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header onClickDrawerOpen={onClickDrawerOpen} isOpen={isOpen} />
          <Sidebar onClickDrawerOpen={onClickDrawerOpen} isOpen={isOpen} />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            style={{ overflow: "auto" }}
          >
            <Toolbar style={{ minHeight: 100 }} />
            {children}
          </Box>
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default React.memo(Layout);
