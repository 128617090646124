export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const signIn = (data) => {
  return {
    type: SIGN_IN,
    payload: data,
  };
};

export const refreshToken = (data) => {
  return {
    type: SIGN_IN,
    payload: data,
  };
};

export const signOut = () => {
  return {
    type: SIGN_OUT,
  };
};
