export const SAVE_MESSAGE_THREAD = "SAVE_MESSAGE_THREAD";
export const CLEAR_MESSAGE_THREAD = "CLEAR_MESSAGE_THREAD";

export const saveMessageThread = (data) => {
  return {
    type: SAVE_MESSAGE_THREAD,
    payload: data,
  };
};

export const clearMessageThread = () => {
  return {
    type: CLEAR_MESSAGE_THREAD,
  };
};