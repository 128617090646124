import { REFRESH_TOKEN, SIGN_IN, SIGN_OUT } from "redux/actions/worker";

const initialState = {
  isLogged: false,
  id: null,
  email: null,
  first_name: null,
  last_name: null,
  phone_number: null,
  access_token: null,
  refresh_token: null,
  expired_at: null,
};

const workerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        ...action.payload,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        ...action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        ...initialState,
      };

    default:
      return { ...state };
  }
};

export default workerReducer;
