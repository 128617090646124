import { useSelector, useDispatch } from "react-redux";
import { signIn } from "redux/actions/worker";

const getWorkerStore = ({ worker }) => worker;

export const getWorkerInfo = () => {
    if (typeof window !== "undefined") {
        const worker = window.localStorage.getItem("worker");
        return worker && JSON.parse(worker);
    }
};

export const useWorker = () => {
    const workerInfo = getWorkerInfo();
    const workerStore = useSelector(getWorkerStore);
    const dispatch = useDispatch();
    if (
        workerInfo &&
        Boolean(workerStore) &&
        !Boolean(workerStore.isLogged)
    ) {
        dispatch(signIn(workerInfo));
    }
    return workerStore;
};
