export const SAVE = "SAVE";
export const CLEAR = "CLEAR";

export const save = (data) => {
  return {
    type: SAVE,
    payload: data,
  };
};

export const clear = () => {
  return {
    type: CLEAR,
  };
};