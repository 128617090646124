import { SAVE_MESSAGE_THREAD, CLEAR_MESSAGE_THREAD } from "redux/actions/messageThread";

const initialState = {
};

const messageThreadReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_MESSAGE_THREAD:
      return {
        ...action.payload,
      };

    case CLEAR_MESSAGE_THREAD:
      return {
        ...initialState
      };

    default:
      return { ...state };
  }
};

export default messageThreadReducer;
