import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware from "redux-thunk";
import worker from "./reducers/worker";
import defaultStore from "./reducers/defaultStore";
import messageThread from "./reducers/messageThread";

const rootReducer = combineReducers({
    worker,
    defaultStore,
    messageThread,
});

const middleware = [];

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunkMiddleware, ...middleware))
);

export default store;
