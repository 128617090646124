import { useSelector, useDispatch } from "react-redux";
import { save } from "redux/actions/defaultStore";

const getDefaultStoreStore = ({ defaultStore }) => defaultStore;

export const getDefaultStoreInfo = () => {
    if (typeof window !== "undefined") {
        const defaultStore = window.localStorage.getItem("defaultStore");
        return defaultStore && JSON.parse(defaultStore);
    }
};

export const useDefaultStore = () => {
    const defaultStoreInfo = getDefaultStoreInfo();
    const defaultStoreStore = useSelector(getDefaultStoreStore);
    const dispatch = useDispatch();
    if (
        defaultStoreInfo &&
        Boolean(defaultStoreStore) &&
        !Boolean(defaultStoreStore.id)
    ) {
        dispatch(save(defaultStoreInfo));
    }
    return defaultStoreStore;
};
