import { createTheme, ThemeProvider } from "@mui/material";
import Home from "pages/Home";
import Page404 from "pages/404";
import SignIn from "pages/SignIn";
import { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import store from "redux/store";
import { SnackbarProvider } from "notistack";
import { COLORS, INTERCOM_APP_ID, ROUTE } from "utils/constants";
import "./App.css";
import Layout from "layout/Layout";
import Orders from "pages/Orders";
import Messages from "pages/Messages";
import Patients from "pages/Patients";
import Workers from "pages/Workers";
import Doctors from "pages/Doctors";
import Invoice from "pages/Invoice";
import Profile from "pages/Profile";
import Priors from "pages/Priors";
import APIKey from "pages/APIKey";
import { useWorker } from "hooks/useWorker";
import ResetPassword from "pages/ResetPassword";
import AddOrder from "pages/Orders/AddOrder";
import ImportOrder from "pages/Orders/ImportOrder";
import PatientDetail from "pages/Patients/PatientDetail";
import ViewOrder from "pages/Orders/ViewOrder";
import Print from "pages/Orders/Print";
import ImportErrorDetail from "pages/Orders/ImportErrorDetail";

import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

import { IntercomProvider, useIntercom } from "react-use-intercom";
import { useDefaultStore } from "hooks/useDefaultStore";
import ViewPrior from "pages/Priors/ViewPrior";

import ViewImportErrorDetail from "pages/Orders/ViewImportErrorDetail";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ViewDoctor from "pages/Doctors/ViewDoctor";

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', sans-serif",
    color: COLORS.GREY.MAIN,
  },
  palette: {
    primary: {
      main: COLORS.PRIMARY.MAIN,
    },
    blue: {
      main: COLORS.BLUE.MAIN,
    },
    grey: {
      main: COLORS.GREY.MAIN,
    },
    yellow: {
      main: COLORS.YELLOW.MAIN,
    },
    white: {
      main: COLORS.WHITE.MAIN,
    },
    red: {
      main: COLORS.RED.MAIN,
    },
    disabled: {
      main: COLORS.DISABLED,
    },
    default: {
      main: COLORS.DEFAULT.MAIN,
    },
  },
});

function PrivateRoute({ children }) {
  const workerStore = useWorker() || {};
  const {
    isLogged = false,
    first_name = "",
    last_name = "",
    email = "",
  } = workerStore || {};
  const defaultStore = useDefaultStore();
  const { id: storeId, npi } = defaultStore || {};
  const location = useLocation();
  const { update } = useIntercom();

  useEffect(() => {
    if (isLogged) {
      update({
        name: `${first_name} ${last_name}`,
        email: email || "",
        npi: storeId && storeId !== "all" ? npi : "",
      });
    }
  }, [storeId, isLogged]);

  return isLogged ? (
    children
  ) : (
    <Navigate to={ROUTE.SIGN_IN.URL} state={{ from: location }} />
  );
}
const LoadingComponent = () => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

function App() {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          preventDuplicate
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Provider store={store}>
              <Router>
                <Suspense fallback={<LoadingComponent />}>
                  <Layout>
                    <Routes>
                      <Route
                        path={ROUTE.SIGN_IN.URL}
                        element={<SignIn />}
                      ></Route>
                      <Route
                        path={ROUTE.RESET_PASSWORD.URL}
                        element={<ResetPassword />}
                      ></Route>
                      <Route
                        path={ROUTE.ROOT.URL}
                        element={
                          <PrivateRoute>
                            <Home />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={`${ROUTE.ORDERS.URL}/*`}
                        element={
                          <PrivateRoute>
                            <Orders />
                          </PrivateRoute>
                        }
                      >
                        <Route path=":id" element={<ViewOrder />} />
                        <Route path=":id/print" element={<Print />} />
                        <Route path="create" element={<AddOrder />} />
                        <Route path="import" element={<ImportOrder />} />
                        <Route
                          path="import/:id/detail"
                          element={<ImportErrorDetail />}
                        />
                        <Route
                          path="import/:id/detail/view"
                          elemen
                          t={<ViewImportErrorDetail />}
                        />
                      </Route>

                      <Route
                        path={ROUTE.MESSAGES.URL}
                        element={
                          <PrivateRoute>
                            <Messages />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={`${ROUTE.MESSAGES.URL}/:id`}
                        element={
                          <PrivateRoute>
                            <Messages />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={`${ROUTE.PATIENTS.URL}/*`}
                        element={
                          <PrivateRoute>
                            <Patients />
                          </PrivateRoute>
                        }
                      >
                        <Route path={":id"} element={<PatientDetail />} />
                      </Route>

                      <Route
                        path={ROUTE.WORKERS.URL}
                        element={
                          <PrivateRoute>
                            <Workers />
                          </PrivateRoute>
                        }
                      />

                      <Route
                        path={ROUTE.DOCTORS.URL}
                        element={
                          <PrivateRoute>
                            <Doctors />
                          </PrivateRoute>
                        }
                      >
                        <Route path={`:id`} element={<ViewDoctor />} />
                      </Route>

                      <Route
                        path={`${ROUTE.PHARMACY_FAX.URL}/*`}
                        element={
                          <PrivateRoute>
                            <Priors />
                          </PrivateRoute>
                        }
                      >
                        <Route path=":id" element={<ViewPrior />} />
                      </Route>
                      <Route
                        path={`${ROUTE.INVOICE.URL}/*`}
                        element={
                          <PrivateRoute>
                            <Invoice />
                          </PrivateRoute>
                        }
                      >
                        <Route path=":id" element={<ViewPrior />} />
                      </Route>

                      <Route
                        path={ROUTE.PROFILE.URL}
                        element={
                          <PrivateRoute>
                            <Profile />
                          </PrivateRoute>
                        }
                      />
                      <Route
                        path={ROUTE.API_KEY_AND_SERVICES.URL}
                        element={
                          <PrivateRoute>
                            <APIKey />
                          </PrivateRoute>
                        }
                      />
                      <Route path={"/404"} element={<Page404 />} />
                      <Route
                        path="*"
                        element={<Navigate replace to="/404" />}
                      ></Route>
                    </Routes>
                  </Layout>
                </Suspense>
              </Router>
            </Provider>
          </LocalizationProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </IntercomProvider>
  );
}

export default App;
